import { ref } from "vue";
import { useRouter } from "vue-router";
import { teamUsers } from "@/api/team"; // 调用接口的函数

import { showLoading, hideLoading } from "@/utils/utils"; // loading 工具

export default {
  name: "teamDetail",

  setup() {
    const router = useRouter();
    const activeTab = ref("levela"); // 默认显示 levela Tab

    const loading = ref(false); // 控制加载状态

    const finished = ref(false); // 控制是否已加载完所有数据

    const items = ref([]); // 数据列表

    const currentPage = ref(1); // 当前页码

    const pageSize = ref(10); // 每页条数

    const selectedType = ref(0); // 默认 type 为 0（Level A）

    const onClickLeft = () => router.back(); // 加载更多数据


    const onLoad = async () => {
      if (loading.value || finished.value) return; // 避免重复加载

      loading.value = true;
      showLoading("Loading...");

      try {
        const response = await teamUsers({
          current: currentPage.value,
          size: pageSize.value,
          type: selectedType.value // 根据选中的类型请求数据

        });

        if (response.code === 1) {
          const {
            records,
            total: responseTotal
          } = response.data;
          items.value.push(...records); // 追加新数据

          if (items.value.length >= responseTotal) {
            finished.value = true; // 已加载完所有数据
          } else {
            currentPage.value += 1; // 加载下一页数据
          }
        }
      } catch (error) {
        console.error("加载数据失败", error);
      }

      hideLoading();
      loading.value = false;
    }; // 切换 Tab 触发事件


    const switchTab = tab => {
      currentPage.value = 1; // 重置分页

      items.value = []; // 清空数据

      finished.value = false; // 重置加载状态

      selectedType.value = tab === "levela" ? 0 : 1; // type 0 为 A，1 为 B

      onLoad(); // 加载数据
    };

    return {
      activeTab,
      onClickLeft,
      onLoad,
      items,
      loading,
      finished,
      switchTab
    };
  }

};