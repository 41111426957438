import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-75e2edd4"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  style: {
    "padding": "0.6rem 1rem 1rem"
  }
};
const _hoisted_2 = {
  class: "amount"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  style: {
    "color": "rgb(102, 102, 102)",
    "font-size": "0.8rem",
    "padding-bottom": "6px"
  }
};
const _hoisted_5 = {
  class: "time"
};
const _hoisted_6 = {
  style: {
    "padding": "0.6rem 1rem 1rem"
  }
};
const _hoisted_7 = {
  class: "amount"
};
const _hoisted_8 = {
  class: "title"
};
const _hoisted_9 = {
  style: {
    "color": "rgb(102, 102, 102)",
    "font-size": "0.8rem",
    "padding-bottom": "6px"
  }
};
const _hoisted_10 = {
  class: "time"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");

  const _component_van_list = _resolveComponent("van-list");

  const _component_van_tab = _resolveComponent("van-tab");

  const _component_van_tabs = _resolveComponent("van-tabs");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_nav_bar, {
    title: "Team Detail",
    "left-arrow": "",
    onClickLeft: $setup.onClickLeft
  }, null, 8, ["onClickLeft"]), _createVNode(_component_van_tabs, {
    modelValue: $setup.activeTab,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $setup.activeTab = $event),
    onChange: $setup.switchTab
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tab, {
      title: "Level A",
      name: "levela"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_list, {
        loading: $setup.loading,
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => $setup.loading = $event),
        finished: $setup.finished,
        "finished-text": "Not more",
        "loading-text": "loading...",
        onLoad: $setup.onLoad
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, item => {
          return _openBlock(), _createElementBlock("div", {
            key: item.loginName,
            class: "card"
          }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(item.userName), 1), _createElementVNode("div", _hoisted_4, " INR Balance: " + _toDisplayString(item.inrBalance), 1), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", null, "Registration time: " + _toDisplayString(item.createDate), 1)])])]);
        }), 128))]),
        _: 1
      }, 8, ["loading", "finished", "onLoad"])])]),
      _: 1
    }), _createVNode(_component_van_tab, {
      title: "Level B",
      name: "levelb"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_van_list, {
        loading: $setup.loading,
        "onUpdate:loading": _cache[1] || (_cache[1] = $event => $setup.loading = $event),
        finished: $setup.finished,
        "finished-text": "Not more",
        "loading-text": "loading...",
        onLoad: $setup.onLoad
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, item => {
          return _openBlock(), _createElementBlock("div", {
            key: item.loginName,
            class: "card"
          }, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(item.userName), 1), _createElementVNode("div", _hoisted_9, " INR Balance: " + _toDisplayString(item.inrBalance), 1), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", null, "Registration time: " + _toDisplayString(item.createDate), 1)])])]);
        }), 128))]),
        _: 1
      }, 8, ["loading", "finished", "onLoad"])])]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue", "onChange"])]);
}